import React, { FC, useState, useContext } from "react";

import PinnedModule from "./PinnedModule";
import ModulesList from "./ModulesList";

import { IMatchedModule, IProfile } from "../../interfaces";
import Context from "../../context";

interface IDashboardState {
    modules: IMatchedModule[];
    profile: IProfile;
}

const Dashboard: FC = () => {
    const { setDrawerOpen, profile } = useContext(Context);

    const [dashboardState, setDashboardState] = useState<IDashboardState>({
        modules: [
            {
                department: {
                    name: "School of Engineering",
                    colour: "pink",
                    hue: "600",
                },
                code: "ES193",
                name: "Engineering Mathematics",
                pinned: true,
                matchCount: 3,
                firstProfiles: [
                    "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
                    "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
                    "https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
                ],
            },
            {
                department: {
                    name: "Department of Computer Science",
                    colour: "pink",
                    hue: "400",
                },
                code: "CS132",
                name: "Computer Organisation & Architecture",
                pinned: true,
                matchCount: 2,
                firstProfiles: [
                    "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
                    "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
                ],
            },
            {
                department: {
                    name: "Department of Computer Science",
                    colour: "pink",
                    hue: "400",
                },
                code: "CS133",
                name: "Professional Skills",
                pinned: true,
                matchCount: 8,
                firstProfiles: [
                    "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
                    "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
                    "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
                    "https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
                ],
            },
            {
                department: {
                    name: "Department of Computer Science",
                    colour: "pink",
                    hue: "400",
                },
                code: "CS118",
                name: "Programming for Computer Scientists 1",
                pinned: false,
                matchCount: 22,
                firstProfiles: [
                    "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
                    "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
                    "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
                    "https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
                ],
            },
        ],
        profile: profile!,
    });

    let { modules } = dashboardState;

    return (
        <div className='flex flex-col min-w-0 flex-1 overflow-hidden'>
            <main
                className='flex-1 relative z-0 overflow-y-auto focus:outline-none'
                tabIndex={0}
            >
                {/* <!-- Page title & actions --> */}
                <div className='relative z-10 flex-shrink-0 flex bg-white border-b border-gray-200 sm:h-22 lg:h-16'>
                    <button
                        className='px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden'
                        onClick={() => setDrawerOpen(true)}
                    >
                        <span className='sr-only'>Open sidebar</span>
                        {/* <!-- Heroicon name: menu-alt-1 --> */}
                        <svg
                            className='h-6 w-6'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            aria-hidden='true'
                        >
                            <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                stroke-widxth='2'
                                d='M4 6h16M4 12h8m-8 6h16'
                            />
                        </svg>
                    </button>
                    <div className='px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 flex-auto'>
                        <div className='flex-1 min-w-0'>
                            <h1 className='text-lg font-medium leading-6 text-gray-900 sm:truncate'>
                                Dashboard
                            </h1>
                        </div>
                        <div className='mt-4 flex sm:mt-0 sm:ml-4'>
                            <button
                                type='button'
                                className='order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:order-1 sm:ml-3'
                            >
                                Add Module
                            </button>
                        </div>
                    </div>
                </div>

                {/* <!-- Pinned modules --> */}
                <div className='px-4 mt-6 sm:px-6 lg:px-8'>
                    <h2 className='text-gray-500 text-xs font-medium uppercase tracking-wide'>
                        Pinned Modules
                    </h2>
                    <ul className='grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-2 xl:grid-cols-4 mt-3'>
                        {modules
                            .filter((m) => m.pinned === true)
                            .map((module, idx) => (
                                <PinnedModule
                                    key={`pinned-module-${idx}`}
                                    {...module}
                                />
                            ))}
                    </ul>
                </div>

                <ModulesList modules={modules} />
            </main>
        </div>
    );
};

export default Dashboard;
