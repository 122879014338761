import React, { FC, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useFirebaseApp } from "reactfire";
import Icon from "../../img/semlinkicon.png";

const VerifyEmail: FC = () => {
    const firebase = useFirebaseApp();
    const queryString = useLocation().search;
    const history = useHistory();

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                const query = new URLSearchParams(queryString);

                const oobCode = query.get("oobCode");

                if (oobCode === null || oobCode === "") {
                    throw Error("You haven't included a code!");
                } else {
                    let result = await firebase.auth().checkActionCode(oobCode);

                    if (result.operation !== "VERIFY_EMAIL") {
                        throw Error(
                            "Your code isn't valid for this operation."
                        );
                    }
                }

                await firebase.auth().applyActionCode(oobCode);
            } catch {
                history.push("/signin");
            }
        };

        verifyEmail();
    }, []);

    return (
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img className="mx-auto h-12 w-auto" src={Icon} alt="Semlink" />
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Email verified
                </h2>

                <div className="mt-5 sm:mt-6">
                    <Link
                        to="/signin"
                        className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    >
                        Sign in
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default VerifyEmail;
