import React, { FC, LabelHTMLAttributes } from "react";

const InputLabel: FC<LabelHTMLAttributes<HTMLLabelElement>> = ({
    children,
    className,
    ...props
}) => (
    <label
        {...props}
        className={
            className
                ? ("block text-sm font-medium text-gray-700 " + className)
                : "block text-sm font-medium text-gray-700"
        }
    >
        {children}
    </label>
);

export default InputLabel;
