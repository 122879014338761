import React, { FC } from "react";
import { Link } from "react-router-dom";
import { object } from "yup";
import { Formik } from "formik";
import { useAuth } from "reactfire";

import firebase from "firebase";

import LoadingButton from "../../components/LoadingButton";
import Input from "../../components/Input";
import { email, password, rememberMe } from "../../validation";

import Icon from "../../img/semlinkicon.png";
import Splash from "../../img/signinsplash.png";
import InputLabel from "../../components/Input/InputLabel";

const SignIn: FC = () => {
    const auth = useAuth();

    return (
        <div className='min-h-screen bg-white flex'>
            <div className='flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24'>
                <div className='mx-auto w-full max-w-sm lg:w-96'>
                    <div>
                        <img className='h-12 w-auto' src={Icon} alt='Semlink' />
                        <h2 className='mt-6 text-3xl font-extrabold text-gray-900'>
                            Sign in to Semlink
                        </h2>
                        <p className='mt-2 text-sm text-gray-600 max-w'>
                            Or{" "}
                            <Link
                                to='/signup'
                                className='font-medium text-indigo-600 hover:text-indigo-500'
                            >
                                create an account
                            </Link>
                        </p>
                    </div>

                    <div className='mt-8'>
                        <div className='mt-6'>
                            <Formik
                                validationSchema={object({
                                    email,
                                    password,
                                    rememberMe,
                                })}
                                initialValues={{
                                    email: "",
                                    password: "",
                                    rememberMe: false,
                                }}
                                onSubmit={async (
                                    { email, password, rememberMe },
                                    { setSubmitting, setErrors }
                                ) => {
                                    try {
                                        const persistence = rememberMe
                                            ? firebase.auth.Auth.Persistence
                                                  .LOCAL
                                            : firebase.auth.Auth.Persistence
                                                  .SESSION;

                                        await auth.setPersistence(persistence);

                                        const authResult = await auth.signInWithEmailAndPassword(
                                            email,
                                            password
                                        );

                                        if (!authResult.user?.emailVerified) {
                                            await auth.currentUser?.sendEmailVerification();
                                            await auth.signOut();
                                            throw new Error(
                                                "Please verify your email. We've sent you another link."
                                            );
                                        }
                                    } catch (error) {
                                        setErrors({
                                            email: error.message,
                                        });
                                    }

                                    setSubmitting(false);
                                }}
                            >
                                {({ handleSubmit, isSubmitting }) => (
                                    <form
                                        onSubmit={handleSubmit}
                                        className='space-y-6'
                                    >
                                        <div>
                                            <InputLabel htmlFor='email'>
                                                Email address
                                            </InputLabel>
                                            <div className='mt-1'>
                                                <Input
                                                    name='email'
                                                    type='email'
                                                    autoComplete='email'
                                                />
                                            </div>
                                        </div>

                                        <div className='space-y-1'>
                                            <InputLabel htmlFor='password'>
                                                Password
                                            </InputLabel>
                                            <div className='mt-1'>
                                                <Input
                                                    name='password'
                                                    type='password'
                                                    autoComplete='current-password'
                                                />
                                            </div>
                                        </div>

                                        <div className='flex items-center justify-between'>
                                            <div className='flex items-center'>
                                                <Input
                                                    name='rememberMe'
                                                    type='checkbox'
                                                    className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
                                                />
                                                <InputLabel
                                                    htmlFor='rememberMe'
                                                    className='ml-2 block text-sm text-gray-900'
                                                >
                                                    Remember me
                                                </InputLabel>
                                            </div>

                                            <div className='text-sm'>
                                                <Link
                                                    to='/forgot'
                                                    className='font-medium text-indigo-600 hover:text-indigo-500'
                                                >
                                                    Forgot your password?
                                                </Link>
                                            </div>
                                        </div>

                                        <div>
                                            <LoadingButton
                                                loading={isSubmitting}
                                            >
                                                Sign in
                                            </LoadingButton>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <div className='hidden lg:block relative w-0 flex-1'>
                <img
                    className='absolute inset-0 h-full w-full object-cover'
                    src={Splash}
                    alt='The CafeLibrary at Warwick'
                />
            </div>
        </div>
    );
};

export default SignIn;
