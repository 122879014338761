import React, { FC, useContext } from "react";
import { Redirect, Route, RouteProps } from "react-router";
import Context from "../../context";

const ProtectedRoute: FC<RouteProps> = ({ ...props }) => {
    const context = useContext(Context);

    return (
        <>
            {context.profile !== null ? (
                <Route {...props} />
            ) : (
                <Redirect to={`/signin?r=${props.path}`} />
            )}
        </>
    );
};

export default ProtectedRoute;
