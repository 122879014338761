import React, { FC, useContext, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import Icon from "../../img/semlinkicon.png";
import Context from "../../context";

import "./WithNavigation.css";
import Profile from "../Profile";
import { useFirebaseApp } from "reactfire";

const WithNavigation: FC = ({ children }) => {
    const firebase = useFirebaseApp();
    const { pathname } = useLocation();
    const { drawerOpen, setDrawerOpen, profile } = useContext(Context);

    const [profileOpen, setProfileOpen] = useState<boolean>(false);

    const classNames = {
        mobile: {
            icon: {
                active: "text-gray-500 mr-4 h-6 w-6",
                inactive:
                    "text-gray-400 group-hover:text-gray-500 mr-4 h-6 w-6",
            },
            navlink: {
                active:
                    "bg-gray-100 text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md",
                inactive:
                    "text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md",
            },
        },
        desktop: {
            icon: {
                active: "text-gray-500 mr-3 h-6 w-6",
                inactive:
                    "text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6",
            },
            navlink: {
                active:
                    "bg-gray-200 text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md",
                inactive:
                    "text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md",
            },
        },
    };

    const routes = [
        {
            to: "/dashboard",
            text: "Dashboard",
            icon: (
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'
                >
                    <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6'
                    />
                </svg>
            ),
        },
        {
            to: "/settings",
            text: "Settings",
            icon: (
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                >
                    <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z'
                    />
                    <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                    />
                </svg>
            ),
        },
    ];

    const bottomNav = (
        <div className='flex-shrink-0 flex border-t border-gray-200 p-4 justify-between'>
            <span className='flex-shrink-0 group block'>
                <div className='flex items-center'>
                    <div>
                        <img
                            className='inline-block h-10 w-10 rounded-full'
                            src={profile?.photoURL ?? ""}
                            alt=''
                        ></img>
                    </div>
                    <div className='ml-3'>
                        <p className='text-base font-medium text-gray-700 group-hover:text-gray-900'>
                            {profile?.displayName}
                        </p>
                        <p
                            className='button text-sm font-medium text-gray-500 group-hover:text-gray-700'
                            onClick={() => {
                                setDrawerOpen(false);
                                setProfileOpen(true);
                            }}
                            role='button'
                        >
                            View profile
                        </p>
                    </div>
                </div>
            </span>
            <button
                type='button'
                className='inline-flex items-center px-3 py-1 border border-transparent rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={async () => {
                    await firebase.auth().signOut();
                }}
            >
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    className='flex-shrink-0 h-5 w-5 '
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                >
                    <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1'
                    />
                </svg>
            </button>
        </div>
    );

    return (
        <div className='h-screen flex overflow-hidden bg-white'>
            {profileOpen && (
                <Profile
                    setProfileOpen={setProfileOpen}
                />
            )}

            {/*} Off-canvas menu for mobile, show/hide based on off-canvas menu state. */}

            <div
                className={`lg:hidden ${drawerOpen ? "visible" : "invisible"}`}
            >
                <div className={`fixed inset-0 flex z-40 drawer ${drawerOpen ? "drawer-open" : ""}`}>
                    {/*
            Off-canvas menu overlay, show/hide based on off-canvas menu state.

            // Entering: "transition-opacity ease-linear duration-300"
            // From: "opacity-0"
            // To: "opacity-100"

            // Leaving: "transition-opacity ease-linear duration-300"
            // From: "opacity-100"
            // To: "opacity-0"
        */}


                        <div
                            className='fixed inset-0 drawer-greyout'
                            onClick={() => {
                                setDrawerOpen(false);
                            }}
                        >
                            <div className='absolute inset-0 bg-gray-600 opacity-75' ></div>
                        </div>

                    {/*
            Off-canvas menu, show/hide based on off-canvas menu state.

            // Entering: "transition ease-in-out duration-300 transform"
            // From: "-translate-x-full"
            // To: "translate-x-0"

            // Leaving: "transition ease-in-out duration-300 transform"
            // From: "translate-x-0"
            // To: "-translate-x-full"
        */}

                        <div className='relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none'>
                            <div className='absolute top-0 right-0 -mr-12 pt-2'>
                                <button
                                    type='button'
                                    className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                                    onClick={() => setDrawerOpen(false)}
                                >
                                    <span className='sr-only'>
                                        Close sidebar
                                    </span>
                                    <svg
                                        className='h-6 w-6 text-white'
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='none'
                                        viewBox='0 0 24 24'
                                        stroke='currentColor'
                                        aria-hidden='true'
                                    >
                                        <path
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth={2}
                                            d='M6 18L18 6M6 6l12 12'
                                        />
                                    </svg>
                                </button>
                            </div>

                            <div className='flex-1 h-0 pt-5 pb-4 overflow-y-auto'>
                                <div className='flex-shrink-0 flex items-center px-4'>
                                    <img
                                        className='h-16 w-auto'
                                        src={Icon}
                                        alt='Semlink'
                                    />
                                    <span className='ml-4 text-4xl'>
                                        Semlink
                                    </span>
                                </div>
                                <nav aria-label='Sidebar' className='mt-5'>
                                    <div className='px-2 space-y-1'>
                                        {routes.map((route, idx) => (
                                            <NavLink
                                                key={`mobile-menu-${idx}`}
                                                to={route.to}
                                                className={
                                                    pathname.startsWith(
                                                        route.to
                                                    )
                                                        ? classNames.mobile
                                                              .navlink.active
                                                        : classNames.mobile
                                                              .navlink.inactive
                                                }
                                                onClick={() =>
                                                    setDrawerOpen(false)
                                                }
                                            >
                                                <svg
                                                    className={
                                                        pathname.startsWith(
                                                            route.to
                                                        )
                                                            ? classNames.mobile
                                                                  .icon.active
                                                            : classNames.mobile
                                                                  .icon.active
                                                    }
                                                >
                                                    {route.icon}
                                                </svg>

                                                {route.text}
                                            </NavLink>
                                        ))}
                                    </div>
                                </nav>
                            </div>

                            {bottomNav}
                        </div>


                    <div
                        className='flex-shrink-0 w-14'
                        aria-hidden='true'
                    ></div>
                </div>
            </div>

            <div className='hidden lg:flex lg:flex-shrink-0'>
                <div className='flex flex-col w-64'>
                    <div className='flex flex-col h-0 flex-1 border-r border-gray-200 bg-gray-100'>
                        <div className='flex-1 flex flex-col pt-5 pb-4 overflow-y-auto'>
                            <div className='flex items-center flex-shrink-0 px-4'>
                                <img
                                    className='h-16 w-auto'
                                    src={Icon}
                                    alt='Semlink'
                                />{" "}
                                <span className='ml-3 text-4xl'>Semlink</span>
                            </div>
                            <nav className='mt-5 flex-1' aria-label='Sidebar'>
                                <div className='px-2 space-y-1'>
                                    {routes.map((route, idx) => (
                                        <NavLink
                                            key={`desktop-menu-${idx}`}
                                            to={route.to}
                                            className={
                                                pathname.startsWith(route.to)
                                                    ? classNames.desktop.navlink
                                                          .active
                                                    : classNames.desktop.navlink
                                                          .inactive
                                            }
                                        >
                                            <svg
                                                className={
                                                    pathname.startsWith(
                                                        route.to
                                                    )
                                                        ? classNames.desktop
                                                              .icon.active
                                                        : classNames.desktop
                                                              .icon.active
                                                }
                                            >
                                                {route.icon}
                                            </svg>

                                            {route.text}
                                        </NavLink>
                                    ))}
                                </div>
                            </nav>
                        </div>
                        {bottomNav}
                    </div>
                </div>
            </div>
            {children}
        </div>
    );
};

export default WithNavigation;
