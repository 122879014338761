import React, { FC } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import "firebase/auth";

import RouteSwitch from "./routing/RouteSwitch";
import { Helmet } from "react-helmet";

const App: FC = () => {
    return (
        <Router>
            <Helmet titleTemplate='%s | Semlink'>Coming Soon</Helmet>
            <RouteSwitch />
        </Router>
    );
};

export default App;
