import { ErrorMessage, Formik } from "formik";
import React, { FC, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { object } from "yup";
import { useFirebaseApp } from "reactfire";

import Icon from "../../img/semlinkicon.png";

import { password, confirmPassword } from "../../validation";
import Input from "../../components/Input";
import LoadingButton from "../../components/LoadingButton";
import InputLabel from "../../components/Input/InputLabel";

const ResetPassword: FC = () => {
    const [resetModal, setResetModal] = useState<boolean>(false);

    const firebase = useFirebaseApp();

    const [resetInfo, setResetInfo] = useState<{
        email?: string | null;
        resetCode?: string;
    }>({
        email: null,
    });

    const queryString = useLocation().search;

    useEffect(() => {
        const verifyCode = async () => {
            try {
                const query = new URLSearchParams(queryString);

                const oobCode: string | null = query.get("oobCode");

                if (oobCode === null || oobCode === "") {
                    throw Error;
                }

                const resetCode: string = oobCode;

                const codeResult = await firebase
                    .auth()
                    .checkActionCode(resetCode);

                console.log(codeResult);

                const email = await firebase
                    .auth()
                    .verifyPasswordResetCode(resetCode);

                setResetInfo({
                    email,
                    resetCode,
                });
            } catch {
                setResetInfo({
                    email: null,
                });
            }
        };

        verifyCode();
    }, []);

    return (
        <>
            {resetModal && (
                <div className='fixed z-10 inset-0 overflow-y-auto'>
                    <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
                        {/* <!--
          Background overlay, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        --> */}

                        <div
                            key='confirm-email-modal-overlay'
                            className='fixed inset-0 transition-opacity'
                            aria-hidden='true'
                        >
                            <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
                        </div>

                        <span
                            className='hidden sm:inline-block sm:align-middle sm:h-screen'
                            aria-hidden='true'
                        >
                            &#8203;
                        </span>
                        {/* <!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        --> */}

                        <div
                            key='confirm-email-modal'
                            className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6'
                            role='dialog'
                            aria-modal='true'
                            aria-labelledby='modal-headline'
                        >
                            <div>
                                <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100'>
                                    <svg
                                        className='h-6 w-6 text-green-600'
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='none'
                                        viewBox='0 0 24 24'
                                        stroke='currentColor'
                                        aria-hidden='true'
                                    >
                                        <path
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth={2}
                                            d='M5 13l4 4L19 7'
                                        />
                                    </svg>
                                </div>
                                <div className='mt-3 text-center sm:mt-5'>
                                    <h3
                                        className='text-lg leading-6 font-medium text-gray-900'
                                        id='modal-headline'
                                    >
                                        Password Reset!
                                    </h3>
                                </div>
                            </div>
                            <div className='mt-5 sm:mt-6'>
                                <Link
                                    to='/signin'
                                    className='inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm'
                                >
                                    Back to sign in
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {resetInfo?.email! !== null ? (
                <div className='min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
                    <div className='sm:mx-auto sm:w-full sm:max-w-md'>
                        <img
                            className='mx-auto h-12 w-auto'
                            src={Icon}
                            alt='Semlink'
                        />
                        <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
                            Reset your password
                        </h2>
                    </div>

                    <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
                        <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
                            <Formik
                                validationSchema={object({
                                    password,
                                    confirmPassword,
                                })}
                                initialValues={{
                                    password: "",
                                    confirmPassword: "",
                                }}
                                onSubmit={async (
                                    { password },
                                    { setErrors }
                                ) => {
                                    try {
                                        await firebase
                                            .auth()
                                            .confirmPasswordReset(
                                                resetInfo.resetCode!,
                                                password
                                            );

                                        setResetModal(true);
                                    } catch (error) {
                                        console.log(error);
                                        setErrors({
                                            password: error.message,
                                        });
                                    }
                                }}
                            >
                                {({ handleSubmit, isSubmitting }) => (
                                    <form
                                        className='space-y-6'
                                        onSubmit={handleSubmit}
                                    >
                                        <div>
                                            <InputLabel htmlFor='password'>
                                                New password
                                            </InputLabel>
                                            <div className='mt-1'>
                                                <Input
                                                    name='password'
                                                    type='password'
                                                    autoComplete='password'
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <InputLabel htmlFor='confirmPassword'>
                                                Confirm password
                                            </InputLabel>
                                            <div className='mt-1'>
                                                <Input
                                                    name='confirmPassword'
                                                    type='password'
                                                    autoComplete='password'
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <LoadingButton
                                                loading={isSubmitting}
                                            >
                                                Reset Password
                                            </LoadingButton>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
                    <div className='sm:mx-auto sm:w-full sm:max-w-md'>
                        <img
                            className='mx-auto h-12 w-auto'
                            src={Icon}
                            alt='Semlink'
                        />
                        <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
                            This reset link is invalid.
                        </h2>

                        <div className='mt-5 sm:mt-6'>
                            <Link
                                to='/signin'
                                className='inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm'
                            >
                                Back to sign in
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ResetPassword;
