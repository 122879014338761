import React, { FC, useState } from "react";
import { Link } from "react-router-dom";

import { IMatchedModule } from "../../interfaces";

interface IModuleListEntryProps {
    module: IMatchedModule;
}

const ModuleListEntry: FC<IModuleListEntryProps> = ({ module }) => {
    let [options, showOptions] = useState<boolean>(false);

    let { department, name, code, pinned, matchCount } = module;

    let deleteModule = () => {
        console.log(`Removing user from module ${code}.`);
    };

    let togglePin = () => {
        console.log(`Toggling pin for ${code}.`);
    };

    return (
        <tr>
            <td className='px-6 py-3 max-w-0 w-1/2 whitespace-nowrap text-sm font-medium text-gray-900'>
                <div className='flex items-center space-x-3 lg:pl-2'>
                    <div
                        className={`flex-shrink-0 w-2.5 h-2.5 rounded-full bg-${department.colour}-${department.hue}`}
                        aria-hidden='true'
                    ></div>
                    <Link
                        to={`/module/${code}`}
                        className='truncate hover:text-gray-600'
                    >
                        <span>
                            {code}{" "}
                            <span className='text-gray-500 font-normal'>
                                {name}
                            </span>
                        </span>
                    </Link>
                </div>
            </td>

            <td className='md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500'>
                <span className='truncate font-normal text-gray-500'>
                    {department.name}
                </span>
            </td>

            <td className='px-6 py-3 text-sm text-gray-500 font-medium'>
                <div className='flex items-center space-x-2'>
                    {matchCount === 0 && (
                        <span className='text-gray-500 font-normal'>None</span>
                    )}

                    <div className='flex flex-shrink-0 -space-x-1'>
                        {module.firstProfiles?.map((photo, idx) => (
                            <img
                                key={`${code}-match-${idx}`}
                                className='max-w-none h-6 w-6 rounded-full ring-2 ring-white'
                                src={photo}
                                alt='Profile pic'
                            />
                        ))}
                    </div>

                    {matchCount > 4 && (
                        <span className='flex-shrink-0 text-xs leading-5 font-medium'>
                            +{matchCount - 4}
                        </span>
                    )}
                </div>
            </td>

            <td
                className='pr-6'
                onBlur={() => {
                    setTimeout(() => {
                        showOptions(false);
                    }, 100);
                }}
            >
                <div className='relative flex justify-end items-center'>
                    <button
                        id='project-options-menu-0'
                        aria-haspopup='true'
                        type='button'
                        className='w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'
                        onClick={() => showOptions(!options)}
                    >
                        <span className='sr-only'>Open options</span>
                        {/* <!-- Heroicon name: dots-vertical --> */}
                        <svg
                            className='w-5 h-5'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 20 20'
                            fill='currentColor'
                            aria-hidden='true'
                        >
                            <path d='M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z' />
                        </svg>
                    </button>
                    {/* <!--
Dropdown panel, show/hide based on dropdown state.

Entering: "transition ease-out duration-100"
From: "transform opacity-0 scale-95"
To: "transform opacity-100 scale-100"
Leaving: "transition ease-in duration-75"
From: "transform opacity-100 scale-100"
To: "transform opacity-0 scale-95"
--> */}

                    <div
                        className={`mx-3 origin-top-right absolute right-7 top-0 w-48 mt-1 rounded-md shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 popup ${
                            options && "popup-open"
                        }`}
                        role='menu'
                        aria-orientation='vertical'
                        aria-labelledby='project-options-menu-0'
                    >
                        <div className='py-1' role='none'>
                            <button
                                className='w-full group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                                role='menuitem'
                                onClick={togglePin}
                            >
                                {/* <!-- Heroicon name: pencil-alt --> */}
                                <svg
                                    className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 20 20'
                                    fill='currentColor'
                                    aria-hidden='true'
                                >
                                    <path d='M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z' />
                                    <path
                                        fillRule='evenodd'
                                        d='M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z'
                                        clipRule='evenodd'
                                    />
                                </svg>
                                Edit
                            </button>
                            <button
                                className='w-full group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                                role='menuitem'
                                onClick={togglePin}
                            >
                                {/* <!-- Heroicon name: duplicate --> */}
                                <svg
                                    className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    stroke='currentColor'
                                    aria-hidden='true'
                                >
                                    <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth={2}
                                        d='M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z'
                                    />
                                    <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth={2}
                                        d='M15 11a3 3 0 11-6 0 3 3 0 016 0z'
                                    />
                                </svg>
                                {pinned ? "Unpin" : "Pin"}
                            </button>
                        </div>
                        <div className='py-1' role='none'>
                            <button
                                className='w-full group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                                role='menuitem'
                                onClick={deleteModule}
                            >
                                {/* <!-- Heroicon name: trash --> */}
                                <svg
                                    className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 20 20'
                                    fill='currentColor'
                                    aria-hidden='true'
                                >
                                    <path
                                        fillRule='evenodd'
                                        d='M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z'
                                        clipRule='evenodd'
                                    />
                                </svg>
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default ModuleListEntry;
