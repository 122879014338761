const firebaseConfig = {
    apiKey: "AIzaSyDu-rWoJ-wkaSQ-GKm7ukQMy2gQ3ADLu-8",
    authDomain: "semlink-a9d7f.firebaseapp.com",
    projectId: "semlink-a9d7f",
    storageBucket: "semlink-a9d7f.appspot.com",
    messagingSenderId: "710353820856",
    appId: "1:710353820856:web:4eaa8cb0f8d29513f3684d",
    measurementId: "G-TTMPP5E3EK",
};

export default firebaseConfig;
