import React, { FC } from "react";
import { Link } from "react-router-dom";

import { IMatchedModule } from "../../interfaces";

import ModuleListEntry from "./ModuleListEntry";

interface IModulesListProps {
    modules: IMatchedModule[];
}

const ModulesList: FC<IModulesListProps> = ({ modules }) => {

    return (
        <>
            {/* <!-- Projects list (only on smallest breakpoint) --> */}

            <div className='mt-10 sm:hidden'>
                <div className='px-4 sm:px-6'>
                    <h2 className='text-gray-500 text-xs font-medium uppercase tracking-wide'>
                        Modules
                    </h2>
                </div>
                <ul className='mt-3 border-t border-gray-200 divide-y divide-gray-100'>
                    {modules.map((module, idx) => (
                        <li key={`module-entry-${idx}`}>
                            <Link
                                to={`/module/${module.code}`}
                                className='group flex items-center justify-between px-4 py-4 hover:bg-gray-50 sm:px-6'>
                                <span className='flex items-center truncate space-x-3'>
                                    <span
                                        className={`w-2.5 h-2.5 flex-shrink-0 rounded-full bg-${module.department.colour}-${module.department.hue}`}
                                        aria-hidden='true'></span>
                                    <span className='font-medium truncate text-sm leading-6'>
                                        {module.code}{" "}
                                        <span className='truncate font-normal text-gray-500'>
                                            {module.name}
                                        </span>
                                    </span>
                                </span>
                                {/* <!-- Heroicon name: chevron-right --> */}
                                <svg
                                    className='ml-4 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 20 20'
                                    fill='currentColor'
                                    aria-hidden='true'>
                                    <path
                                        fillRule='evenodd'
                                        d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                                        clipRule='evenodd'
                                    />
                                </svg>
                            </Link>
                        </li>
                    ))}

                    {/* <!-- More project rows... --> */}
                </ul>
            </div>

            {/* <!-- Projects table (small breakpoint and up) --> */}
            <div className='hidden mt-8 sm:block'>
                <div className='align-middle inline-block min-w-full border-b border-gray-200'>
                    <table className='min-w-full'>
                        <thead>
                            <tr className='border-t border-gray-200'>
                                <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                    <span className='lg:pl-2'>Modules</span>
                                </th>
                                <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                    Department
                                </th>
                                <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                    Matches
                                </th>
                                <th className='pr-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider'></th>
                            </tr>
                        </thead>
                        <tbody className='bg-white divide-y divide-gray-100'>
                            {modules.map((module, idx) => (
                                <ModuleListEntry
                                    key={`module-list-entry-${idx}`}
                                    module={module}
                                />
                            ))}

                            {/* <!-- More project rows... --> */}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default ModulesList;
