import React, { FC, useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router";
import ResetPassword from "../../views/ResetPassword";
import VerifyEmail from "../../views/VerifyEmail";

const FireBaseHandler: FC = () => {
    const queryString = useLocation().search;

    const [modeToRender, setModeToRender] = useState<string>("");

    useEffect(() => {
        const query = new URLSearchParams(queryString);

        const mode = query.get("mode");

        if (mode === null) {
            // Consider displaying some sort of error on none?
            setModeToRender("none");
        } else {
            setModeToRender(mode);
        }
    }, []);

    return (
        <>
            {modeToRender === "resetPassword" && <ResetPassword />}
            {modeToRender === "verifyEmail" && <VerifyEmail />}
            {modeToRender === "none" && <Redirect to="/signin" />}
        </>
    );
};

export default FireBaseHandler;
