import React, { FC, useState, useContext } from "react";
import { Formik } from "formik";

import { IProfile } from "../../interfaces";
import Context from "../../context";
import Input from "../../components/Input";
import LoadingButton from "../../components/LoadingButton";
import InputLabel from "../../components/Input/InputLabel";

interface ISettingsState {
    profile: IProfile;
}

const Settings: FC = () => {
    const { setDrawerOpen, profile } = useContext(Context);

    const [settingsState, setSettingsState] = useState<ISettingsState>({
        profile: profile!,
    });

    return (
        <div className='flex flex-col min-w-0 flex-1 overflow-hidden'>
            <main
                className='flex-1 relative z-0 overflow-y-auto focus:outline-none'
                tabIndex={0}
            >
                {/* <!-- Page title & actions --> */}
                <div className='relative z-10 flex-shrink-0 flex bg-white border-b border-gray-200 sm:h-22 lg:h-16'>
                    <button
                        className='px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden'
                        onClick={() => setDrawerOpen(true)}
                    >
                        <span className='sr-only'>Open sidebar</span>
                        {/* <!-- Heroicon name: menu-alt-1 --> */}
                        <svg
                            className='h-6 w-6'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            aria-hidden='true'
                        >
                            <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                stroke-widxth='2'
                                d='M4 6h16M4 12h8m-8 6h16'
                            />
                        </svg>
                    </button>
                    <div className='px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 flex-auto'>
                        <div className='flex-1 min-w-0'>
                            <h1 className='text-lg font-medium leading-6 text-gray-900 sm:truncate'>
                                Settings
                            </h1>
                        </div>
                        <div className='mt-4 flex sm:mt-0 sm:ml-4'>
                            <span className='inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800'>
                                Last saved at 17:21
                            </span>
                        </div>
                    </div>
                </div>

                <div className='max-w-7xl mx-auto lg:py-12 lg:px-8'>
                    <div className='space-y-6 sm:px-6 lg:px-0 lg:col-span-9'>
                        <form action='#' method='POST'>
                            <section aria-labelledby='profile_heading'>
                                <div className='shadow sm:rounded-md sm:overflow-hidden'>
                                    <div className='bg-white py-6 px-4 sm:p-6'>
                                        <div>
                                            <h2
                                                id='profile_heading'
                                                className='text-lg leading-6 font-medium text-gray-900'
                                            >
                                                Profile
                                            </h2>
                                            <p className='mt-1 text-sm text-gray-500'>
                                                Update your profile here. This
                                                information is publically
                                                visible to anyone you match
                                                with. If you are unsure of any
                                                information you can find it on
                                                your profile on Tabula.
                                            </p>
                                        </div>

                                        <div className='mt-6 grid grid-cols-4 gap-6'>
                                            <div className='col-span-4 sm:col-span-2'>
                                                <label
                                                    htmlFor='displayName'
                                                    className='block text-sm font-medium text-gray-700'
                                                >
                                                    Display name
                                                </label>
                                                <input
                                                    type='text'
                                                    name='displayName'
                                                    id='displayName'
                                                    autoComplete='displayName'
                                                    className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
                                                />
                                            </div>

                                            <div className='col-span-4 sm:col-span-2'>
                                                <label
                                                    htmlFor='profilePhoto'
                                                    className='block text-sm font-medium text-gray-700'
                                                >
                                                    Profile Photo
                                                </label>
                                                <button
                                                    type='button'
                                                    className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
                                                >
                                                    Change
                                                </button>
                                            </div>

                                            <div className='col-span-4 sm:col-span-2'>
                                                <label
                                                    htmlFor='course'
                                                    className='block text-sm font-medium text-gray-700'
                                                >
                                                    Course Title
                                                </label>
                                                <input
                                                    type='text'
                                                    name='course'
                                                    id='course'
                                                    autoComplete='course'
                                                    className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
                                                    placeholder='e.g. Computer Science'
                                                />
                                            </div>

                                            {/* <div className="col-span-4 sm:col-span-1">
                                        <label htmlFor="security_code" className="flex items-center text-sm font-medium text-gray-700">
                                            <span>Security code</span>
                                            <!-- Heroicon name: question-mark-circle -->
                                            <svg className="ml-1 flex-shrink-0 h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                            </svg>
                                        </label>
                                        <input type="text" name="security_code" id="security_code" autoComplete="cc-csc" className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm" />
                                        </div> */}

                                            <div className='col-span-4 sm:col-span-2'>
                                                <InputLabel htmlFor='homeDept'>
                                                    Home Department
                                                </InputLabel>
                                                <select
                                                    id='homeDept'
                                                    name='homeDept'
                                                    autoComplete='homeDept'
                                                    className='mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
                                                >
                                                    <option value=''>
                                                        Select a department
                                                    </option>
                                                    <optgroup label='Faculty of Arts'>
                                                        <option value='ren'>
                                                            Centre for the Study
                                                            of the Renaissance
                                                        </option>
                                                        <option value='classics'>
                                                            Classics and Ancient
                                                            History
                                                        </option>
                                                        <option value='arts'>
                                                            Comparative American
                                                            Studies
                                                        </option>
                                                        <option value='english'>
                                                            English and
                                                            Comparative Literary
                                                            Studies
                                                        </option>
                                                        <option value='film'>
                                                            Film and Television
                                                            Studies
                                                        </option>
                                                        <option value='history'>
                                                            History
                                                        </option>
                                                        <option value='arthistory'>
                                                            History of Art
                                                        </option>
                                                        <option value='scapvc'>
                                                            School of Creative
                                                            Arts, Performance
                                                            and Visual Cultures
                                                        </option>
                                                        <option value='ccmps'>
                                                            Cultural Media
                                                            Policy Studies
                                                        </option>
                                                        <option value='theatre'>
                                                            Theatre and
                                                            Performance Studies
                                                        </option>
                                                        <option value='writingprog'>
                                                            Warwick Writing
                                                            Programme
                                                        </option>
                                                        <option value='modernlanguages'>
                                                            School of Modern
                                                            Languages and
                                                            Cultures
                                                        </option>
                                                        <option value='schoolforcross-facultystudies'>
                                                            School for
                                                            Cross-faculty
                                                            Studies
                                                        </option>
                                                        <option value='gsd'>
                                                            Global Sustainable
                                                            Development
                                                        </option>
                                                        <option value='liberalarts'>
                                                            Liberal Arts
                                                        </option>
                                                    </optgroup>
                                                    <optgroup
                                                        label='Faculty of Science,
                                                        Engineering and
                                                        Medicine'
                                                    >
                                                        <option value='chemistry'>
                                                            Chemistry
                                                        </option>
                                                        <option value='dcs'>
                                                            Computer Science
                                                        </option>
                                                        <option value='eng'>
                                                            Engineering
                                                        </option>
                                                        <option value='lifesci'>
                                                            Life Sciences
                                                        </option>
                                                        <option value='maths'>
                                                            Mathematics
                                                        </option>
                                                        <option value='physics'>
                                                            Physics
                                                        </option>
                                                        <option value='psych'>
                                                            Psychology
                                                        </option>
                                                        <option value='statistics'>
                                                            Statistics
                                                        </option>
                                                        <option value='systemsbiology'>
                                                            Systems Biology 
                                                        </option>
                                                        <option value='wmg'>
                                                            Warwick
                                                            Manufacturing Group
                                                            (WMG)
                                                        </option>
                                                        <option value='med'>
                                                            Warwick Medical
                                                            School
                                                        </option>
                                                    </optgroup>
                                                    <optgroup
                                                        label='Faculty of Social
                                                        Sciences'
                                                    >
                                                        <option value='al'>
                                                            Applied Linguistics
                                                        </option>
                                                        <option value='cll'>
                                                            Centre for Lifelong
                                                            Learning
                                                        </option>
                                                        <option value='economics'>
                                                            Economics
                                                        </option>
                                                        <option value='ces'>
                                                            Education: Centre
                                                            for Education
                                                            Studies & Centre for
                                                            Teacher Education
                                                        </option>
                                                        <option value='ier'>
                                                            Institute for
                                                            Employment Research
                                                        </option>
                                                        <option value='law'>
                                                            Law
                                                        </option>
                                                        <option value='philosophy'>
                                                            Philosophy
                                                        </option>
                                                        <option value='pais'>
                                                            Politics and
                                                            International
                                                            Studies
                                                        </option>
                                                        <option value='sociology'>
                                                            Sociology
                                                        </option>
                                                        <option value='wbs'>
                                                            Warwick Business
                                                            School
                                                        </option>
                                                        <option value='warwickfoundationstudies'>
                                                            Warwick Foundation
                                                            Studies
                                                        </option>
                                                    </optgroup>
                                                </select>
                                            </div>

                                            <div className='col-span-4 sm:col-span-4'>
                                                <label
                                                    htmlFor='bio'
                                                    className='block text-sm font-medium text-gray-700'
                                                >
                                                    Bio
                                                </label>
                                                <textarea
                                                    name='bio'
                                                    id='bio'
                                                    minLength={40}
                                                    autoComplete='bio'
                                                    className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
                                        <button
                                            type='submit'
                                            className='bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900'
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </form>

                        <br />
                        {/* <section aria-labelledby='email_heading'>
                                <div className='shadow sm:rounded-md sm:overflow-hidden'>
                                    <div className='bg-white py-6 px-4 sm:p-6'>
                                        <div>
                                            <h2
                                                id='email_heading'
                                                className='text-lg leading-6 font-medium text-gray-900'>
                                                Email
                                            </h2>
                                            <p className='mt-1 text-sm text-gray-500'>
                                                Update your email address here.
                                                We only use this for XXX.
                                            </p>
                                        </div>

                                        <div className='mt-6 grid grid-cols-4 gap-6'>
                                            <div className='col-span-4 sm:col-span-4'>
                                                <label
                                                    htmlFor='email'
                                                    className='block text-sm font-medium text-gray-700'>
                                                    Email address
                                                </label>
                                                <input
                                                    type='email'
                                                    name='email'
                                                    id='email'
                                                    autoComplete='email'
                                                    className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <br /> */}
                        <Formik
                            initialValues={{
                                ...profile!.connections,
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                console.log(values);
                                setSubmitting(false);
                            }}
                        >
                            {({ handleSubmit, isSubmitting }) => (
                                <form onSubmit={handleSubmit}>
                                    <section aria-labelledby='social_heading'>
                                        <div className='shadow sm:rounded-md sm:overflow-hidden'>
                                            <div className='bg-white py-6 px-4 sm:p-6'>
                                                <div>
                                                    <h2
                                                        id='social_heading'
                                                        className='text-lg leading-6 font-medium text-gray-900'
                                                    >
                                                        Connections
                                                    </h2>
                                                    <p className='mt-1 text-sm text-gray-500'>
                                                        Update your social media
                                                        handles here. Remember
                                                        this information is
                                                        visible to anyone else
                                                        in your labs, seminars
                                                        or workshops.
                                                    </p>
                                                </div>

                                                <div className='mt-6 grid grid-cols-4 gap-6'>
                                                    <div className='col-span-4 sm:col-span-2'>
                                                        <InputLabel htmlFor='website'>
                                                            Website
                                                        </InputLabel>
                                                        <div className='mt-1'>
                                                            <Input
                                                                type='text'
                                                                name='website'
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='col-span-4 sm:col-span-2'>
                                                        <InputLabel htmlFor='twitter'>
                                                            Twitter
                                                        </InputLabel>
                                                        <div className='mt-1'>
                                                            <Input
                                                                type='text'
                                                                name='twitter'
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='col-span-4 sm:col-span-2'>
                                                        <InputLabel htmlFor='instagram'>
                                                            Instagram
                                                        </InputLabel>
                                                        <div className='mt-1'>
                                                            <Input
                                                                type='text'
                                                                name='instagram'
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='col-span-4 sm:col-span-2'>
                                                        <InputLabel htmlFor='snapchat'>
                                                            Snapchat
                                                        </InputLabel>
                                                        <div className='mt-1'>
                                                            <Input
                                                                type='text'
                                                                name='snapchat'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-span-4 sm:col-span-2'>
                                                        <InputLabel htmlFor='github'>
                                                            GitHub
                                                        </InputLabel>
                                                        <div className='mt-1'>
                                                            <Input
                                                                type='text'
                                                                name='github'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-span-4 sm:col-span-2'>
                                                        <InputLabel htmlFor='discord'>
                                                            Discord
                                                        </InputLabel>
                                                        <div className='mt-1'>
                                                            <Input
                                                                type='text'
                                                                name='discord'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
                                                <LoadingButton
                                                    loading={isSubmitting}
                                                    className='bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900'
                                                >
                                                    Save
                                                </LoadingButton>
                                            </div>
                                        </div>
                                    </section>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Settings;
