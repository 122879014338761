import { createContext } from "react";
import { IProfile } from "./interfaces";

interface IContextValue {
    drawerOpen: boolean;
    setDrawerOpen: (drawerOpen: boolean) => void;
    profile?: IProfile | null;
}

const Context = createContext<IContextValue>({
    drawerOpen: false,
    setDrawerOpen: () => {},
    profile: undefined,
});

export default Context;
