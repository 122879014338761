import React, { FC, ButtonHTMLAttributes } from "react";

interface ILoadingButtonProps {
    loading: boolean;
}

const LoadingButton: FC<
    ILoadingButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
> = ({ loading, type = "submit", children, ...props }) => {
    return (
        <button
            type='submit'
            disabled={loading}
            className={`${
                loading ? "animate-pulse" : ""
            } w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            {...props}>
            {loading ? "Loading" : children}
        </button>
    );
};

export default LoadingButton;
