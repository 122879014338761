import React, { FC, InputHTMLAttributes } from "react";
import { Field, FieldProps } from "formik";

const Input: FC<InputHTMLAttributes<HTMLInputElement>> = ({
    name,
    className,
    ...props
}) => {
    className = className
        ? className
        : "appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm";

    return (
        <Field name={name}>
            {({ field, meta: { touched, error } }: FieldProps) => (
                <>
                    <input
                        id={name}
                        className={
                            (className +=
                                touched && error
                                    ? " ring-red-500 border-red-500"
                                    : " border-gray-300")
                        }
                        {...field}
                        {...props}
                    />
                    {touched && error && (
                        <span className="flex items-center font-medium text-red-500 text-xs mt-1 ml-1">
                            {error}
                        </span>
                    )}
                </>
            )}
        </Field>
    );
};

export default Input;
