import React, { FC } from "react";

const NotFound: FC = () => (
    <div className='flex h-screen items-center bg-red-600'>
        <div className='max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8'>
            <h2 className='text-3xl font-extrabold text-white sm:text-4xl'>
                <span className='block'>This page has gone walkies!</span>
                <span className='block'>Sorry about that. 😔</span>
            </h2>
            <p className='mt-4 text-lg leading-6 text-red-200'>
                It seems like whatever's supposed to be at this link has gone
                missing. Please double check the link you've followed and try
                again a little later.
            </p>
        </div>
    </div>
);

export default NotFound;
