import React, { FC, useState } from "react";
import { Link } from "react-router-dom";

import { IMatchedModule } from "../../interfaces";

const PinnedModule: FC<IMatchedModule> = ({
    name,
    matchCount,
    code,
    department,
}) => {
    const [options, showOptions] = useState<boolean>(false);

    const modulePrefix = code.slice(0, 2);
    const { colour, hue } = department;

    return (
        <li
            className='relative col-span-1 flex shadow-sm rounded-md'
            onBlur={() => {
                setTimeout(() => {
                    showOptions(false);
                }, 100);
            }}>
            <div
                className={`flex-shrink-0 flex items-center justify-center w-16 bg-${colour}-${hue} text-white text-sm font-medium rounded-l-md`}>
                {modulePrefix}
            </div>
            <div className='flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate'>
                <div className='flex-1 px-4 py-2 text-sm truncate'>
                    <Link
                        to={`/module/${code}`}
                        className='text-gray-900 font-medium hover:text-gray-600'>
                        {name}
                    </Link>
                    <p className='text-gray-500'>{matchCount} matches</p>
                </div>
                <div className='flex-shrink-0 pr-2'>
                    <button
                        id='pinned-project-options-menu-0'
                        aria-haspopup='true'
                        className='w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'
                        onClick={() => showOptions(!options)}>
                        <span className='sr-only'>Open options</span>
                        {/* <!-- Heroicon name: dots-vertical --> */}
                        <svg
                            className='w-5 h-5'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 20 20'
                            fill='currentColor'
                            aria-hidden='true'>
                            <path d='M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z' />
                        </svg>
                    </button>
                    {/* <!--
                  Dropdown panel, show/hide based on dropdown state.

                  Entering: "transition ease-out duration-100"
                    From: "transform opacity-0 scale-95"
                    To: "transform opacity-100 scale-100"
                  Leaving: "transition ease-in duration-75"
                    From: "transform opacity-100 scale-100"
                    To: "transform opacity-0 scale-95"
                --> */}

                    
                        <div
                            className={`z-10 mx-3 origin-top-right absolute right-10 top-3 w-48 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 popup ${options ? 'popup-open' : ''}`}
                            role='menu'
                            aria-orientation='vertical'
                            aria-labelledby='pinned-project-options-menu-0'>
                            <div className='py-1' role='none'>
                                <Link
                                    to='/signin'
                                    className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                                    role='menuitem'>
                                    Removed from pinned
                                </Link>
                            </div>
                        </div>
                    
                </div>
            </div>
        </li>
    );
};

export default PinnedModule;
