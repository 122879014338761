import React, { FC, useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useAuth } from "reactfire";
import { useHistory, useLocation } from "react-router-dom";

import { Helmet } from "react-helmet";

import SignIn from "../../views/SignIn";
import SignUp from "../../views/SignUp";
import Dashboard from "../../views/Dashboard";
import ForgotPassword from "../../views/ForgotPassword";
import Settings from "../../views/Settings";
import Landing from "../../views/Welcome";
import NotFound from "../../views/NotFound";

import WithNavigation from "../../components/WithNavigation";
import FireBaseHandler from "../../components/FireBaseHandler";
import ProtectedRoute from "../../routing/ProtectedRoute";

import Context from "../../context";

import { IProfile } from "../../interfaces";

const RouteSwitch: FC = () => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [profile, setProfile] = useState<IProfile | null>(null);

    const auth = useAuth();
    const history = useHistory();
    const queryString = useLocation().search;

    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            console.log(user);
            if (user !== null && user.emailVerified) {
                // Make request for userData
                let userData = {
                    bio: "I like cats. Quite a bit.",
                    course: "Computer Science",
                    homeDept: {
                        name: "Department of Computer Science",
                        colour: "pink",
                        hue: "400",
                    },
                    connections: {
                        discord: "NotBarkingMad#2585",
                        snapchat: "jakedoesnotgowoof",
                        github: "definitelynotjake",
                        instagram: "chasingmytail",
                        twitter: "birdchaser1821",
                        website: "definitelynotjake.com",
                    },
                    photoURL:
                        "https://avatars2.githubusercontent.com/u/76539535?s=400&amp;u=40641f1b4c985fe014399db21299f849ec76a61c&amp;v=4",
                };

                setProfile({
                    ...user,
                    ...userData,
                });
            } else {
                setProfile(null);
            }
        });
    }, []);

    useEffect(() => {
        if (profile) {
            let query = new URLSearchParams(queryString);

            let redirect = query.get("r");

            if (redirect === null || redirect === "") {
                redirect = "/dashboard";
            }

            history.push(redirect);
        }
    }, [profile]);

    return (
        <Context.Provider
            value={{
                drawerOpen,
                setDrawerOpen,
                profile,
            }}
        >
            <Switch>
                <Route path='/' exact>
                    <Landing />
                </Route>
                <Route path='/signin'>
                    <Helmet>
                        <title>Sign In</title>
                    </Helmet>
                    <SignIn />
                </Route>
                <Route path='/signup'>
                    <Helmet>
                        <title>Sign Up</title>
                    </Helmet>
                    <SignUp />
                </Route>
                <ProtectedRoute path='/dashboard'>
                    <Helmet>
                        <title>Dashboard</title>
                    </Helmet>
                    <WithNavigation>
                        <Dashboard />
                    </WithNavigation>
                </ProtectedRoute>
                <ProtectedRoute path='/settings'>
                    <Helmet>
                        <title>Settings</title>
                    </Helmet>
                    <WithNavigation>
                        <Settings />
                    </WithNavigation>
                </ProtectedRoute>
                <Route path='/forgot'>
                    <Helmet>
                        <title>Reset Password</title>
                    </Helmet>
                    <ForgotPassword />
                </Route>
                <Route path='/umgmt'>
                    <Helmet>
                        <title>User Management</title>
                    </Helmet>
                    <FireBaseHandler />
                </Route>
                <Route>
                    <Helmet>
                        <title>404</title>
                    </Helmet>
                    <NotFound />
                </Route>
            </Switch>
        </Context.Provider>
    );
};

export default RouteSwitch;
